import React from 'react';
import './Specials.sass';

class Specials extends React.Component {
    render() {
        return (
            <main>
                <p className='test-class'>Specials</p>
            </main>
        );
    }
}

export default Specials;